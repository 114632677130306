import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import AllMyLinks from "./pages/allMyLinks";

const Root = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<AllMyLinks />} />
            </Routes>
        </Router>
    );
};

export default Root;