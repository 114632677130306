import React from "react";
import LinkButton from "../components/buttonForLink/linkButton";
import "./Style.css";
import logoFace from "../components/buttonForLink/face.png";
import logoInsta from "../components/buttonForLink/insta.png";
import logoWpp from "../components/buttonForLink/wpp.png";

const AllMyLinks = () => {
    return (            
            <main className="mainContainer">
                 <header className="logoContainer">
                     <h1 style={{color:"rgba(0,0,0,0)"}}>Dani Tattoo Beauty Sorocaba</h1>
            </header>             
                <div className="buttonContainer">
                    <LinkButton imgUrl={logoFace} name="Facebook" linkUrl="https://www.facebook.com/dani.tattoo.estudio">  </LinkButton>
                    <LinkButton imgUrl={logoInsta} name="Instagram" linkUrl="https://www.instagram.com/danitattoobeauty/"></LinkButton>
                    <LinkButton imgUrl={logoWpp} name="WhatsApp" linkUrl="https://api.whatsapp.com/send?phone=5515998312601&text=Vim%20do%20site"></LinkButton>
                </div>
            </main>
    )
}

export default AllMyLinks;
