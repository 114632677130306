import React from "react";
import "./linkButton.css";

class LinkButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <a className="button" href={this.props.linkUrl} target="_blank" rel="noreferrer" >
                <div className="buttonOrganizer">
                    <img className="buttonImg" src={this.props.imgUrl} alt="logo_button" />
                    <p>{this.props.name}</p>
                </div>
            </a>
        )
    }
}

export default LinkButton;